import React, {useEffect, useState} from "react";
import {RouterProvider} from "react-router-dom";
import {router} from "./Router";
import {createTheme, globalCss, NextUIProvider, styled} from "@nextui-org/react";
import useDarkMode from "use-dark-mode";
import {HelmetProvider} from "react-helmet-async";
import {globalStyles} from "./globalStyles";

import { Analytics } from "@vercel/analytics/react"

const sharedTheme = {
    breakpoints: {
        xs: '490px',
        sm: '800px',
        md: '1120px',
        lg: '1240px',
        xl: '1600px',
    },
    fonts: {
        sans: `'Source Sans 3', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;`,
    },
    // letterSpacings: {
    //     tighter: '-0.05em',
    //     tight: '-0.025em',
    //     normal: '0.1px',
    //     wide: '0.025em',
    //     wider: '0.05em',
    //     widest: '0.1em'
    // },
    fontSizes: {
        xs: '0.75rem', /* 12px */
        sm: '0.875rem', /* 14px */
        base: '1.075rem', /* 16px */
        md: '1.075rem', /* 16px */
        lg: '1.125rem', /* 18px */
        xl: '1.25rem', /* 20px */
        '2xl': '1.5rem', /* 24px */
        '3xl': '1.875rem', /* 30px */
        '4xl': '2.25rem', /* 36px */
        '5xl': '3rem', /* 48px */
        '6xl': '3.75rem', /* 60px */
        '7xl': '4.5rem', /* 72px */
        '8xl': '6rem', /* 96px */
        '9xl': '8rem', /* 128px */
    }
};

const darkTheme = createTheme({
    type: "dark", // it could be "light" or "dark"
    theme: {
        ...sharedTheme,
        colors: {
            backgroundAlpha: '#26292BAA',
        },
    },
});

const lightTheme = createTheme({
    type: "light", // it could be "light" or "dark"
    theme: {
        ...sharedTheme,
        colors: {

        }
    },
});

const StyledNextUIProvider = styled(NextUIProvider, {
    "p": {
        letterSpacing: '$normal'
    },
    // not the last paragraph
    ".markdown-body > p:not(:last-child)": {
        marginBottom: '0.85rem'
    },
});
function App() {
    const [visible, setVisible] = useState(false);
    const darkMode = useDarkMode(false);

    useEffect(() => {
        setVisible(true);
    }, []);
    globalStyles();
    return (
        <>
            <Analytics/>
            <HelmetProvider>
                <NextUIProvider disableBaseline theme={darkMode.value ? darkTheme : lightTheme}>
                    { visible && <RouterProvider router={router}/> }
                </NextUIProvider>
            </HelmetProvider>
        </>
    );
}

export default App;
