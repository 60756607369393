import React, {useRef, useState} from 'react';
import {Link, Navbar, StyledNavbarContainer, useTheme} from "@nextui-org/react";
import {ReactComponent as Logo} from "../logo.svg";
import {Link as RouterLink, useLocation, useNavigate} from "react-router-dom";
import CV from "../routes/CV";

const navigationItems = [
    {
        url: '/',
        reg: /^\/$/,
        name: 'About'
    },
    // {
    //     url: '/posts',
    //     reg: /^\/posts\/?.*/,
    //     name: 'Writing'
    // },
    // {
    //     url: '/projects',
    //     reg: /^\/projects\/?.*/,
    //     name: 'Projects'
    // }
];

function Navigation(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const {theme} = useTheme();

    const navbarToggleRef = useRef();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [cvModalOpen, setCvModalOpen] = useState(false);

    return (
        <>
            <Navbar sm isCompact variant="floating" css={!isMobileMenuOpen ? {
                [`& ${StyledNavbarContainer}`]: {
                    boxShadow: props.scrollTop === 0 ? 'none' : '$shadows$md',
                    background: props.scrollTop === 0 ? 'transparent' : theme.colors.backgroundAlpha.value,
                    transition: "box-shadow 200ms, background-color 200ms",
                    maxWidth: "$breakpoints$sm",
                    mx: "auto",
                    px: "calc($space$sm * 2)",
                    '@xsMax': {
                        px: "calc($space$xs * 2)",
                        mx: "$space$sm",
                    }
                },
            } : {}}>
                <Navbar.Collapse>
                    {
                        navigationItems.map((item) => (
                            <Navbar.CollapseItem key={item.url} isActive={item.reg.test(location.pathname)}>
                                <Link onPress={() => {
                                    navigate(item.url);
                                    isMobileMenuOpen && navbarToggleRef.current.click();
                                }} color="inherit">
                                    {item.name}
                                </Link>
                            </Navbar.CollapseItem>
                        ))
                    }
                </Navbar.Collapse>

                <RouterLink to="/">
                    <Navbar.Brand>
                        <Logo style={{height: 32}} fill={theme.colors.text.value}/>
                    </Navbar.Brand>
                </RouterLink>
                <Navbar.Content enableCursorHighlight hideIn="xs">
                    {
                        navigationItems.map((item) => (
                            <Navbar.Link onPress={() => {
                                navigate(item.url)
                            }} key={item.url} isActive={item.reg.test(location.pathname)}>{item.name}</Navbar.Link>
                        ))
                    }
                </Navbar.Content>
                <Navbar.Toggle showIn="xs" ref={navbarToggleRef}
                               onChange={(isSelected) => setIsMobileMenuOpen(isSelected)}/>
            </Navbar>
            <CV visible={cvModalOpen} setVisible={setCvModalOpen}/>
        </>
    );
}

export default Navigation;