import {createBrowserRouter} from "react-router-dom";
import Root from "./routes/Root";
import Home from "./routes/Home";
import Blog from "./routes/Blog";
import Projects from "./routes/Projects";
import BlogView from "./routes/BlogView";
import mdfiles from "./content";
import matter from "gray-matter";
import Error from "./routes/Error";
import CVResult from "./routes/CVResult";
import {slugify} from "./utils";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        errorElement: <Error/>,
        loader: async () => {
            let posts = {};
            for (const file of Object.values(mdfiles)) {
                await fetch(file)
                    .then((res) => res.text())
                    .then((md) => {
                        const {data, content} = matter(md);
                        // check if data.slug is set
                        if (!data.slug) {
                            data.slug = slugify(data.title);
                        }
                        posts[data.slug] = {data, content};
                    });
            }
            return {posts};
        },
        children: [
            {
                index: true,
                element: <Home/>,
            },
            {
                path: 'posts',
                children: [
                    {
                        index: true,
                        element: <Blog/>
                    },
                    {
                        path: ':slug',
                        element: <BlogView/>
                    }
                ]
            },
            {
                path: 'projects',
                element: <Projects/>
            },
            {
                path: 'cv-success',
                element: <CVResult/>
            }
        ]
    }
])