import React, {useEffect, useState} from 'react';
import Fuse from 'fuse.js';
import {useOutletContext} from "react-router-dom";
import {Grid, Input, Link, Spacer, Text} from "@nextui-org/react";
import Post from "../components/Post";
import {Helmet} from "react-helmet-async";


function Blog(props) {
    const {posts} = useOutletContext();
    const [filteredPosts, setFilteredPosts] = useState([]);
    const [tags, setTags] = useState([]);
    const [activeTags, setActiveTags] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        let tags = [];
        Object.values(posts).forEach((post) => {
            if (post.data.tags) {
                tags = [...tags, ...post.data.tags];
            }
        });
        // sort tags
        tags.sort();
        setTags([...new Set(tags)]);
    }, [posts]);

    // filter posts
    useEffect(() => {
        let filteredPosts = Object.values(posts);
        // sort by date
        filteredPosts.sort((a, b) => {
            return new Date(b.data.date) - new Date(a.data.date);
        });
        if (activeTags.length > 0) {
            filteredPosts = filteredPosts.filter((post) => {
                if (post.data.tags) {
                    return post.data.tags.some((tag) => activeTags.includes(tag));
                }
                return false;
            });
        }
        if (search) {
            const fuse = new Fuse(filteredPosts, {
                keys: ['data.title', 'content'],
                threshold: 0.3,
            });

            filteredPosts = fuse.search(search).map((result) => result.item);
        }
        setFilteredPosts(filteredPosts);
    }, [posts, activeTags, search]);

    return (
        <>
            <Helmet>
                <title>Writings | Steven Gao</title>
            </Helmet>
            <Grid.Container gap={0}>
                <Grid xs={12} md={8}>
                    <Text h2>{
                        activeTags.length > 0 ? activeTags.map((tag) => `#${tag}`).join(' ') : 'All Posts'
                    }</Text>
                </Grid>
                <Grid xs={12} md={4}>
                    <Input css={{width: '100%'}} placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)}/>
                </Grid>
            </Grid.Container>
            <Spacer y={.5} css={{'@md': {display: 'none'}}}/>
            <div>
                {
                    tags.map((tag) => (
                        <Link
                            key={tag}
                            css={{
                                marginRight: 8,
                                color: activeTags.includes(tag) ? '$colors$primary' : '$colors$accents8',
                                fontWeight: activeTags.includes(tag) ? 'bold' : 'normal',
                            }}
                            onClick={() => {
                                if (activeTags.includes(tag)) {
                                    setActiveTags(activeTags.filter((t) => t !== tag));
                                } else {
                                    setActiveTags([...activeTags, tag]);
                                }
                            }}
                        >#{tag}</Link>
                    ))
                }
            </div>

            <Spacer y={1}/>
            {
                Object.values(filteredPosts).map((post, index) => (
                    <Post key={index} {...post.data}/>
                ))
            }
        </>
    );
}

export default Blog;