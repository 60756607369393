import React from 'react';
import {styled} from "@nextui-org/react";

const Footer = styled('div', {
    boxSizing: 'border-box',
    '@xsMax': {
        px: '$1',
    },
    my: "$8",
});

export default Footer;