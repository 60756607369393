import React from 'react';
import {Link, Row, Spacer, Text, useTheme} from "@nextui-org/react";
import {LocalMdContent} from "../components/MdContent";
import content from "../content/home/index.md";
import PostPreview from "../components/PostPreview";
import {IoArrowForwardOutline} from "react-icons/io5";
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet-async";

function RecentPostsSection(props) {
    const navigate = useNavigate();

    return (
        <>
            <Text h3>
                Recent Posts
            </Text>
            <PostPreview/>
            <Spacer y={0.5}/>
            <Row css={{justifyContent: 'end'}}>
                <Link css={{
                    display: 'flex',
                    alignItems: 'center',
                    // gap
                    '& > *:not(:last-child)': {
                        marginRight: '$2',
                    },
                    fontWeight: '600'
                }}
                      onPress={() =>
                          navigate('/posts')
                      }>
                    <div>
                        All Posts
                    </div>
                    <IoArrowForwardOutline/>
                </Link>
            </Row>
            <Spacer y={1.5}/>
        </>
    );
}

const Home = (props) => {
    return (
        <>
            <Helmet>
                <title>Steven Gao</title>
            </Helmet>
            <LocalMdContent file={content}/>
            <Spacer y={2}/>
            {/*<RecentPostsSection/>*/}
        </>
    )
};

export default Home;