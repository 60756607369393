import React from 'react';
import {Button, NextUIProvider, Spacer, Text} from "@nextui-org/react";
import {useNavigate} from "react-router-dom";

function Error(props) {
    const navigate = useNavigate();
    return (
        <NextUIProvider>
            <div style={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Text>I realize today that nothing in the world is more distasteful to a man than to take the path that leads to himself.</Text>
                <Text color="$colors$accents8">Hermann Hesse</Text>
                <Spacer y={1}/>
                <Button onPress={() => {navigate('/')}}>Home</Button>
            </div>
        </NextUIProvider>
    );
}

export default Error;