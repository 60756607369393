import React, {useEffect, useState} from 'react';
import Navigation from "../components/Navigation";
import {Container, Link, Row, Switch, Text, styled} from "@nextui-org/react";
import {Outlet, useLoaderData} from "react-router-dom";
import {Box} from "../components/Box";
import {Buffer} from "buffer";
import Footer from "../components/Footer";
import {IoMoonOutline, IoSunnyOutline} from "react-icons/io5";
import useDarkMode from "use-dark-mode";

window.Buffer = Buffer;

const Page = styled('div', {
    minHeight: '100vh',
});

function Root(props) {
    const [scrollTop, setScrollTop] = useState(0);
    const darkMode = useDarkMode(true);
    const {posts} = useLoaderData();

    useEffect(() => {
        function onScroll(e) {
            setScrollTop(e.target.documentElement.scrollTop);
        }

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);

    return (
        <Page css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            <Navigation scrollTop={scrollTop}/>
            <Container sm css={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                flexGrow: 1,
            }}>
                <Box css={{mt: "$8", "@xsMax": {px: "$1"}, maxWidth: '100%'}}>
                    <Outlet context={{posts}}/>
                </Box>
            </Container>
            <Container sm>
                <Footer>
                    <Row css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Text color="$accents8">
                            Copyright &copy; {new Date().getFullYear()} <Link>Steven Gao</Link>
                        </Text>
                        <Switch
                            iconOff={<IoSunnyOutline/>}
                            iconOn={<IoMoonOutline/>}
                            color="secondary"
                            checked={darkMode.value}
                            onChange={() => darkMode.toggle()}
                        />
                    </Row>
                </Footer>
            </Container>
        </Page>
    );
}

export default Root;