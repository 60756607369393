import React from 'react';
import {Link, Spacer, Text} from "@nextui-org/react";
import {useNavigate} from "react-router-dom";

function Post(props) {
    const navigate = useNavigate();
    return (
        <>
            <Link onPress={() => navigate('/posts/' + props.slug)}>
                <Text h4 weight='bold' css={{marginBottom: 0}}>{props.title}</Text>
            </Link>
            <Text>{props.date.toLocaleDateString('en-US', {month: 'short', day: 'numeric', year: 'numeric'})}</Text>
            <Spacer y={1}/>
        </>
    );
}

export default Post;