import React from 'react';
import {Navigate, useNavigate, useOutletContext, useParams} from "react-router-dom";
import {Text} from "@nextui-org/react";
import MdContent from "../components/MdContent";
import {Helmet} from "react-helmet-async";

function BlogView(props) {
    const {slug} = useParams();
    const {posts} = useOutletContext();

    const post = Object.values(posts).filter((post) => {
        return post.data.slug === slug;
    })[0];

    if (!post) {
        return <Navigate to="/404" replace/>;
    }

    return (
        <>
            <Helmet>
                <title>{post.data.title} | Steven Gao</title>
            </Helmet>
            <Text color="$colors$accents8">{post.data.date.toLocaleDateString('en-US', {month: 'short', day: 'numeric', year: 'numeric'})}</Text>
            <Text h2>{post.data.title}</Text>
            <MdContent children={post.content}/>
        </>
    );
}

export default BlogView;