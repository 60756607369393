import React from 'react';
import MdContent from "../components/MdContent";

const content = `
### A copy of my resume will be sent to your email shortly
Thank you for your interest in learning about who I am. If you would like to connect further, please feel free to reach out to me at any time. I would be more than happy to chat and get to know you as well.

> #### Praise Song for the Day
> *Elizabeth Alexander*
> Each day we go about our business,
> walking past each other, catching each other’s
> eyes or not, about to speak or speaking.
>
> All about us is noise. All about us is
> noise and bramble, thorn and din, each
> one of our ancestors on our tongues.
> 
> Someone is stitching up a hem, darning
> a hole in a uniform, patching a tire,
> repairing the things in need of repair.
> 
> Someone is trying to make music somewhere,
> with a pair of wooden spoons on an oil drum,
> with cello, boom box, harmonica, voice.
> 
> A woman and her son wait for the bus.
> A farmer considers the changing sky.
> A teacher says, Take out your pencils. Begin.
> 
> We encounter each other in words, words
> spiny or smooth, whispered or declaimed,
> words to consider, reconsider.
> 
> We cross dirt roads and highways that mark
> the will of some one and then others, who said
> I need to see what’s on the other side.
> 
> I know there’s something better down the road.
> We need to find a place where we are safe.
> We walk into that which we cannot yet see.
> 
> Say it plain: that many have died for this day.
> Sing the names of the dead who brought us here,
> who laid the train tracks, raised the bridges,
> 
> picked the cotton and the lettuce, built
> brick by brick the glittering edifices
> they would then keep clean and work inside of.
> 
> Praise song for struggle, praise song for the day.
> Praise song for every hand-lettered sign,
> the figuring-it-out at kitchen tables.
> 
> Some live by love thy neighbor as thyself,
> others by first do no harm or take no more
> than you need. What if the mightiest word is love?
> 
> Love beyond marital, filial, national,
> love that casts a widening pool of light,
> love with no need to pre-empt grievance.
> 
> In today’s sharp sparkle, this winter air,
> any thing can be made, any sentence begun.
> On the brink, on the brim, on the cusp,
> 
> praise song for walking forward in that light.
`

const CvResult = (props) => (
    <MdContent className="markdown-content">
        {content}
    </MdContent>
);

export default CvResult;