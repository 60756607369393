import React, {useState} from 'react';
import {Button, Input, Loading, Modal, Text} from "@nextui-org/react";
import {IoMail} from "react-icons/io5";
import {useNavigate} from "react-router-dom";

function CV(props) {
    const {visible, setVisible} = props;
    const [sending, setSending] = useState(false);
    const [error, setError] = useState("");

    const navigate = useNavigate();

    const sendHandler = () => {
        setSending(true);
        setTimeout(() => {
            setSending(false);
            setError("Email is not valid");
            navigate('/cv-success');
        }, 2000);
    };

    return (
        <>
            <Modal closeButton aria-labelledby="CV" open={visible} onClose={() => setVisible(false)}>
                <Modal.Header>
                    <Text id="modal-title" size={18}>
                        Receive a copy of my resume
                    </Text>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        clearable
                        bordered
                        fullWidth
                        color="primary"
                        size="lg"
                        placeholder="Email"
                        contentLeft={<IoMail fill="currentColor"/>}
                    />
                    {error && <Text color="$colors$error">{error}</Text>}
                </Modal.Body>
                <Modal.Footer>
                    <Button auto disabled={sending} onPress={sendHandler}>
                        {sending ? <Loading type="points" color="currentColor" size="sm"
                                            css={{display: sending ? 'block' : 'none'}}/> : 'Send a copy'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CV;