const posts = require.context('./posts', true, /.md$/);

const obj = {};
posts.keys().forEach((key) => {
    // remove the file extension
    const k = key.split('./').pop().split('.md')[0]
    // read the file
    obj[k] = posts(key);
});

export default obj;