export const formatDate = (date) => date.toLocaleDateString('en-US', {month: 'short', day: 'numeric', year: 'numeric'});

export const addAlpha = (color, alpha) => {
    // add alpha to hex color
    return color + alpha.toString(16).padStart(2, '0');
}

export const slugify = str =>
    str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');