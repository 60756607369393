import React, {createRef, useEffect, useLayoutEffect, useRef, useState} from 'react'
import Markdown from 'react-markdown'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'

import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you
import remarkGfm from "remark-gfm";
import {Button, Text, Grid, theme, styled} from "@nextui-org/react";
import remarkBreaks from "remark-breaks";

import SyntaxHighlighter from 'react-syntax-highlighter'
import {github, nord} from "react-syntax-highlighter/dist/cjs/styles/hljs";
import useDarkMode from "use-dark-mode";
import {BiExpand, BiCollapse, BiCopy} from "react-icons/bi";

import {Scrollbars} from 'react-custom-scrollbars';

function reducedHeading(level) {
    return (props) => {
        return <Text h1={level === 1} h2={level === 2} h3={level === 3} h4={level === 4} h5={level === 5}
                     h6={level === 6} {...props} />
    }
}

const PreTagWithRef = React.forwardRef((props, ref) => {
    return <pre ref={ref} {...props}/>
});

const CodeBlock = ({node, inline, className, children, ...props}) => {
    const darkMode = useDarkMode(false);
    const match = /language-(\w+)/.exec(className || '');
    const [expanded, setExpanded] = useState(false);
    const [expandVisible, setExpandVisible] = useState(false);

    const ref = createRef();

    useLayoutEffect(() => {
        if (ref.current != null && ref.current.clientWidth < ref.current.scrollWidth) {
            setExpandVisible(true);
        } else {
            setExpandVisible(false);
        }
    }, [ref]);

    let theme = darkMode.value ? nord : github;
    return !inline && match ? (
        <div style={{
            backgroundColor: theme.hljs.background,
            borderRadius: '0.5rem',
            overflow: 'hidden',
            position: 'relative',
        }}>
            <Grid.Container style={{
                justifyContent: 'flex-end',
                position: 'absolute',
                right: 0,
                top: 0,
                zIndex: 1,
            }} gap={2}>
                <Button light auto icon={<BiCopy size={20}/>} onPress={() => navigator.clipboard.writeText(children)}/>
                {(expandVisible || expanded) &&
                    <Button light auto icon={expanded ? (<BiCollapse size={20}/>) : <BiExpand size={22}/>} onPress={
                        () => {
                            setExpanded(!expanded);
                        }
                    }/>
                }
            </Grid.Container>
            <Scrollbars autoHide autoHeight autoHeightMax={expanded ? '100%' : '30rem'}>
                <SyntaxHighlighter
                    {...props}
                    children={String(children).replace(/\n$/, '')}
                    customStyle={{
                        margin: 0,
                        overflow: 'visible',
                        px: '0.5rem',
                        paddingTop: '1rem',
                        paddingBottom: '1rem',
                    }}
                    style={theme}
                    language={match[1]}
                    showLineNumbers={true}
                    wrapLines={expanded}
                    lineProps={expanded ? {style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}} : {}}
                    PreTag={(props) => <PreTagWithRef {...props} ref={ref}/>}
                    CodeTag="div"
                />
            </Scrollbars>
        </div>


    ) : (
        <code {...props} className={className}>
            {children}
        </code>
    );
};

const MdContent = (props) => (
    <Markdown
        children={props.children}
        remarkRehypeOptions={{footnoteLabel: 'References', footnoteLabelTagName: 'h3'}}
        remarkPlugins={[remarkMath, remarkGfm, remarkBreaks]}
        rehypePlugins={[rehypeKatex]}
        components={{
            h1: reducedHeading(2),
            h2: reducedHeading(3),
            h3: reducedHeading(4),
            h4: reducedHeading(5),
            h5: reducedHeading(6),
            code: CodeBlock
        }}
        // className={'markdown-body'}
        {...props}
    />
);

export function LocalMdContent(props) {
    const [content, setContent] = useState("");

    useEffect(() => {
        fetch(props.file)
            .then((res) => res.text())
            .then((md) => {
                setContent(md);
            });
    }, []);
    return (
        <MdContent children={content}/>
    );
}

export default MdContent;